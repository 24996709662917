import ApiService from "../../api_service";

/** Exception lancée lors d'une suppression d'objet (lorsque ce dernier a des sous-objets encore associés). */
export class DeleteObjectException extends Error {
  constructor(message) {
    super(message);
  }
}

export class ObjectsService extends ApiService {
  /**
   *
   * @param {*} transmissionApi l'api tranmission
   * @param {*} activityApi l'api des activités
   */
  constructor(objectsApi, activityApi) {
    super(objectsApi);

    this.objectsApi = objectsApi;
    this.activityApi = activityApi;
  }

  /**Retourne la liste des activités du SI. */
  async getActivities() {
    return this.activityApi
      .getAll()
      .then((activities) => activities.filter((a) => !!a.name));
  }

  /**
   * Retourne la définition d'un objet 'transmission' (avec id, label, activityIds : Array, subobject : Array).
   * Utiliser dans la vue 'DetailTransmissions et Edittransmission'
   * @param {*} objectId
   */
  async loadObjectById(objectId) {
    let activities = await this.activityApi.getAll();

    let resource = await this.objectsApi.getById(objectId);

    let obj = { ...resource };

    let list = await this.objectsApi.getActivities(obj.id);

    list = list.map((a) => a.activityId); //on conserve uniquement les activityId

    //on mappe les activités
    obj.activities = activities.filter((a) => list.includes(a.id));

    //on récupère les sous-objets
    obj.subobjects = await this.objectsApi.getSubobjects(objectId);

    return obj;
  }

  /**Chargement de la liste des objets. Utiliser dans la vue 'TableTransmissions'. */
  async loadObjects() {
    let objects = [];

    let activities = await this.activityApi.getAll();

    let objs = await this.objectsApi.getAll();

    objs.forEach((obj) => {
      objects.push({ ...obj });
    });

    // on récupère les sous-objets
    for (let obj of objects) {
      let list = await this.objectsApi.getSubobjects(obj.id);
      obj.subobjects = list;

      let nb = obj.subobjects.length;

      obj.nbSubobjects = nb + " sous-objet" + (nb > 1 ? "s" : "");
    }

    //on récupère les activités
    for (let obj of objects) {
      let list = await this.objectsApi.getActivities(obj.id);

      list = list.map((a) => a.activityId); //on conserve uniquement les activityId

      //on mappe les activités
      obj.activities = activities.filter((a) => list.includes(a.id));

      //on trie
      obj.nameActivities = obj.activities
        .map((a) => a.digitalName)
        .sort()
        .join("/");
    }

    return objects;
  }

  /** Définir les activités de l'objet */
  async addActivities(objectId, activityIds) {
    for (let activityId of activityIds) {
      await this.objectsApi.addActivity(objectId, JSON.stringify(activityId));
    }
  }

  /** Définir les activités de l'objet */
  async addSubobjects(objectId, subobjects) {
    for (let subobject of subobjects) {
      await this.objectsApi.addSubobject(objectId, subobject);
    }
  }

  /** Retourne true si les activités ont changés (+/-) */
  activitiesHaveChanged(initials, activities) {
    let initialIds = initials.map((a) => a.id);
    let activityIds = activities.map((a) => a.id);

    //les activités ajoutées
    let added = activityIds.filter((id) => !initialIds.includes(id));

    //les activités retirées
    let removed = initialIds.filter((id) => !activityIds.includes(id));

    //on vérifie s'il y a eu un ajout/retrait
    return added.length > 0 || removed.length > 0;
  }

  /** Mettre à jour les activités de l'objet. Prend en entrée les activités initiales ainsi que la liste des activités en cours.
   * Effectue le diff pour trouver les :
   * - nouvelles activités à ajouter à l'objet
   * - les activités à retirer de l'objet   *
   */
  async updateActivities(objectId, initials, activities) {
    let initialIds = initials.map((a) => a.id);
    let activityIds = activities.map((a) => a.id);

    //les activités ajoutées
    let added = activityIds.filter((id) => !initialIds.includes(id));

    //les activités retirées
    let removed = initialIds.filter((id) => !activityIds.includes(id));

    for (let activityId of added) {
      await this.objectsApi.addActivity(objectId, activityId);
    }

    for (let activityId of removed) {
      await this.objectsApi.removeActivity(objectId, activityId);
    }
  }

  /** Mettre à jour les sous-objets */
  async updateSubobjects(objectId, initials, subobjects) {
    let initialIds = initials.map((a) => a.id);
    let subobjectsIds = subobjects.map((a) => a.id);

    //les sous-objets ajoutés
    let added = subobjects.filter((o) => !o.id);

    //les sous-objets retirés
    let removed = initialIds.filter((id) => !subobjectsIds.includes(id));

    //les sous-objets modifiés
    let updated = subobjects
      .filter((o) => initialIds.includes(o.id))
      .filter((a) => {
        let b = initials.find((e) => e.id === a.id);
        if (!b) return false;
        return a.label !== b.label;
      });

    for (let subobject of added) {
      await this.objectsApi.addSubobject(objectId, subobject.label);
    }

    for (let subobjectId of removed) {
      await this.objectsApi.removeSubobject(objectId, subobjectId);
    }

    for (let subobject of updated) {
      await this.objectsApi.updateSubobject(objectId, subobject);
    }
  }

  /**Retourne true si la liste des sous-objets a changé (+/-/update) */
  subobjectsHaveChanged(initials, subobjects) {
    let initialIds = initials.map((a) => a.id);
    let subobjectsIds = subobjects.map((a) => a.id);

    //les sous-objets ajoutés
    let added = subobjects.filter((o) => !o.id);

    //les sous-objets retirés
    let removed = initialIds.filter((id) => !subobjectsIds.includes(id));

    //les sous-objets modifiés
    let updated = subobjects
      .filter((o) => initialIds.includes(o.id))
      .filter((a) => {
        let b = initials.find((e) => e.id === a.id);
        if (!b) return false;
        return a.label !== b.label;
      });

    //on vérifie si une des grandeurs est modifiée
    return added.length > 0 || removed.length > 0 || updated.length > 0;
  }

  /**Supprime l'objet. La cascade est réalisée à la main car la route l'api ne propose pas cette fonctionnalité (rules.CRUD). */
  async deleteObjectById(objectId) {
    let obj = await this.loadObjectById(objectId);

    if (obj.subobjects.length > 0) {
      throw new DeleteObjectException(
        "Suppression impossible : l'objet a des sous-objets associés."
      );
    }

    //on supprime les activités
    for (let activity of obj.activities) {
      await this.objectsApi.removeActivity(objectId, activity.id);
    }

    //on supprime les sous-objets
    for (let subobject of obj.subobjects) {
      await this.objectsApi.removeSubobject(objectId, subobject.id);
    }

    //on supprime l'objet en lui-même
    await super.delete(objectId);
  }

  // ================================================================
  // ================================================================

  // async getTestActivities() {
  //   return this.objectsApi.getActivitiesTest();
  // }

  // async getTestObjectsByActivity(activityId) {
  //   return this.objectsApi.getObjectsByActivitiesTest(activityId);
  // }

  // async getSubObjectsByActivitiesTest(activityId, objectId) {
  //   return this.objectsApi.getSubObjectsByActivitiesTest(activityId, objectId);
  // }

  // async getMaxtrixForSubobjectTest(subobjectId, emergencylevelId) {
  //   return this.objectsApi.getMaxtrixForSubobjectTest(
  //     subobjectId,
  //     emergencylevelId
  //   );
  // }

  // async getTicketsApplicationsTest() {
  //   return this.objectsApi.getTicketsApplicationsTest();
  // }

  // async getTicketsServicesForAppTest(applicationId) {
  //   return this.objectsApi.getTicketsServicesForAppTest(applicationId);
  // }

  // async getTicketsObjectsForAppTest(applicationId, serviceId) {
  //   return this.objectsApi.getTicketsObjectsForAppTest(
  //     applicationId,
  //     serviceId
  //   );
  // }

  // async getTicketsSubObjectsForAppTest(applicationId, serviceId, objectId) {
  //   return this.objectsApi.getTicketsSubObjectsForAppTest(
  //     applicationId,
  //     serviceId,
  //     objectId
  //   );
  // }

  // async getTicketMaxtrixForSubobjectTest(subobjectId, emergencylevelId) {
  //   return this.objectsApi.getTicketMaxtrixForSubobjectTest(
  //     subobjectId,
  //     emergencylevelId
  //   );
  // }
}
