// import ApiService from "../../api_service";

export class TicketingService {
  constructor(api) {
    this.api = api;
  }

  async exportTransmissionMatrix() {
    return this.api.exportTransmissionMatrix();
  }

  async importTransmissionMatrix() {
    return this.api.importTransmissionMatrix();
  }

  async exportTicketMatrix() {
    return this.api.exportTicketMatrix();
  }

  async importTicketMatrix() {
    return this.api.importTicketMatrix();
  }

  async getUrlGsheetTicketing() {
    return this.api.getUrlGsheetTicketing();
  }

  async getUrlGsheetTransmission() {
    return this.api.getUrlGsheetTransmission();
  }
}
